import type { Campaign } from '@/@types';

const defaultCampaign = { id: 1, name: "Test", assets: [], gm: null, members: [], characters: [] }

export type State = {
    campaigns: Campaign[]
}

export const state: State = {
    campaigns: [defaultCampaign],
};