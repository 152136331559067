import { ActionTree, ActionContext } from 'vuex';

import { RootState } from "@/store"

import { state, State } from './state'
import { Mutations } from "./mutations"
import { CharactersMutationTypes } from "./mutation-types"
import { CharactersActionTypes } from "./action-types"
import { Character } from "@/@types"

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CharactersActionTypes.ADD_CHARACTER](
        { commit }: AugmentedActionContext,
        character: Character
    ): Promise<boolean>;
    [CharactersActionTypes.REMOVE_CHARACTER](
        { commit }: AugmentedActionContext,
        id: number
    ): Promise<boolean>;
    [CharactersActionTypes.SET_CHARACTER_USER](
        { commit }: AugmentedActionContext,
        payload: {
            id: number,
            userID: number
        }
    ): Promise<boolean>;
    [CharactersActionTypes.SET_CHARACTER_CAMPAIGN](
        { commit }: AugmentedActionContext,
        payload: {
            id: number,
            campaignID: number
        }
    ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CharactersActionTypes.ADD_CHARACTER]({ commit }, character: Character) {
        character.id = state.id
        if (character.id >= 0 && !!character.name) {
            commit(CharactersMutationTypes.ADD_CHARACTER, character);
            return true;
        }
        return false
    },
    async [CharactersActionTypes.REMOVE_CHARACTER]({ commit }, id: number) {
        const characterIndex = state.characters.findIndex((x) => x.id === id)
        if (characterIndex >= 0) {
            commit(CharactersMutationTypes.REMOVE_CHARACTER_ID, characterIndex);
            return true;
        }
        return false;
    },
    async [CharactersActionTypes.SET_CHARACTER_USER]({ commit }, payload: { id: number, userID: number }) {
        const characterIndex = state.characters.findIndex((x) => x.id === payload.id)
        if (characterIndex >= 0) {
            const character = state.characters[characterIndex]
            character.userID = payload.userID
            commit(CharactersMutationTypes.EDIT_CHARACTER, { index: characterIndex, character: character });
            return true;
        }
        return false;
    },
    async [CharactersActionTypes.SET_CHARACTER_CAMPAIGN]({ commit }, payload: { id: number, campaignID: number }) {
        const characterIndex = state.characters.findIndex((x) => x.id === payload.id)
        if (characterIndex >= 0) {
            const character = state.characters[characterIndex]
            character.activeCampaign = payload.campaignID
            commit(CharactersMutationTypes.EDIT_CHARACTER, { index: characterIndex, character: character });
            return true;
        }
        return false;
    },

}