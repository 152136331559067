
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { mapGetters } from 'vuex';

import { AssetsActionTypes } from '@/store/modules/assets/action-types';
import { CharactersActionTypes } from '@/store/modules/characters/action-types';

import { Asset, Character } from '@/@types';

export default defineComponent({
  methods: {
    // Asset Methods
    getAllAssets() {
      console.log(this.getAssets)
    },
    getAssetByID() {
      console.log(this.getAssetById(this.id))
    },
    async addAsset() {
      const asset: Asset = { name: this.name, type: this.type, url: this.url };
      try {
        await this.store.dispatch(AssetsActionTypes.ADD_ASSET, asset).then((response) => {
          if (response) {
            this.name = '';
            this.type = '';
            this.url = '';
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteAssetByID() {
      try {
        await this.store.dispatch(AssetsActionTypes.REMOVE_ASSET, this.id).then((response) => {
          if (response) {
            // Give user alert that deleted
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    // Campaign Methods ---
    testCa() {
      console.log(this.store.getters.getCampaigns)
    },

    // Character Methods
    getAllCharacters() {
      console.log(this.getCharacters)
    },
    getCharacterByID() {
      console.log(this.getCharacterById(this.id))
    },
    getCharacterByUserID() { 
      console.log(this.getCharactersByUserId(this.id))

    },
    async addCharacter() {
      const character: Character = { name: this.name, url: this.url };
      try {
        await this.store.dispatch(CharactersActionTypes.ADD_CHARACTER, character).then((response) => {
          if (response) {
            this.name = '';
            this.type = '';
            this.url = '';
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteCharacterByID() {
      try {
        await this.store.dispatch(CharactersActionTypes.REMOVE_CHARACTER, this.id).then((response) => {
          if (response) {
            // Give user alert that deleted
          }
        })
      } catch (e) {
        console.log(e)
      }

    },
    async setUserID() {
      try {
        await this.store.dispatch(CharactersActionTypes.SET_CHARACTER_USER, { id: this.id, userID: this.otherid }).then((response) => {
          if (response) {
            // Give user alert that deleted
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async setCampaignID() {
      try {
        await this.store.dispatch(CharactersActionTypes.SET_CHARACTER_CAMPAIGN, { id: this.id, campaignID: this.otherid }).then((response) => {
          if (response) {
            // Give user alert that deleted
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    // User Methods
    async testU() {
      console.log(this.getUsers)
      console.log(this.getUserById(0))
      console.log(this.getUserById(1))
    },
  },
  data() {
    return {
      store: useStore(),
      name: '',
      type: '',
      url: '',
      id: 0,
      otherid: 0
    }
  },
  computed: {
    ...mapGetters(['getAssets', 'getAssetById']),
    ...mapGetters(['getUsers', 'getUserById']),
    ...mapGetters(['getCharacters', 'getCharacterById', 'getCharactersByUserId'])
  },
})
