import type { Asset } from '@/@types';

export type State = {
    assets: Asset[];
    id: number;
}

export const state: State = {
    assets: [],
    id: 0,
};