import { GetterTree } from "vuex";

import type { User } from "@/@types";

import { RootState } from "@/store";

import { State } from "./state";

export type Getters = {
    getUsers(state: State): User[]
    getUserById(state: State): (id: number) => User | undefined
}

export const getters: GetterTree<State, RootState> & Getters = {
    getUsers: (state) => state.users,
    getUserById: (state) => (id) => {
        return state.users.find(x => x.id === id)
    }
}