
import VariableTable from '@/components/VariableTable.vue'; // @ is an alias to /src
import { defineComponent } from 'vue';
export default defineComponent({
  components: { VariableTable },
  methods: {
    testM() {
      console.log("TEST")
    }
  }
})
