import { GetterTree } from "vuex";

import type { Character } from "@/@types";

import { RootState } from "@/store";

import { State } from "./state";

export type Getters = {
    getCharacters(state: State): Character[]
    getCharacterById(state: State): (id: number) => Character | undefined
    getCharactersByUserId(state: State): (userId: number) => Character[]
}

export const getters: GetterTree<State, RootState> & Getters = {
    getCharacters: (state) => state.characters,
    getCharacterById: (state) => (id) => { return state.characters.find(x => x.id === id) },
    getCharactersByUserId: (state) => (userId) => { return state.characters.filter(x => x.userID === userId) },
}