import { createStore, createLogger } from 'vuex';

import { store as assets, AssetsStore, State as AssetsState } from './modules/assets'
import { store as campaigns, CampaignsStore, State as CampaignsState } from './modules/campaigns'
import { store as characters, CharactersStore, State as CharactersState } from './modules/characters'
import { store as users, UsersStore, State as UsersState } from './modules/users'

export type RootState = {
    assets: AssetsState
    campaigns: CampaignsState;
    characters: CharactersState;
    users: UsersState
};


export type Store = AssetsStore<Pick<RootState, 'assets'>>
    & CampaignsStore<Pick<RootState, 'campaigns'>>
    & CharactersStore<Pick<RootState, 'characters'>>
    & UsersStore<Pick<RootState, 'users'>>

export const store = createStore({
    modules: {
        assets,
        campaigns,
        characters,
        users
    }
});

export function useStore(): Store {
    return store as Store
}