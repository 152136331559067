import { GetterTree } from "vuex";

import type { Campaign } from "@/@types";

import { RootState } from "@/store";

import { State } from "./state";

// define method with typed args and typed return
export type Getters = {
    getCampaigns(state: State): Campaign[]
}

// implement method with typed args and typed return predefined by above
export const getters: GetterTree<State, RootState> & Getters = {
    getCampaigns: (state) => state.campaigns
}