import type { Character } from '@/@types';

export type State = {
    characters: Character[]
    id: number
}

export const state: State = {
    characters: [],
    id: 0
};