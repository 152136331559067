import { ActionTree, ActionContext } from 'vuex';

import { RootState } from "@/store"

import { state, State } from './state'
import { Mutations } from "./mutations"
import { AssetsMutationTypes } from "./mutation-types"
import { AssetsActionTypes } from "./action-types"
import { Asset } from "@/@types"

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [AssetsActionTypes.ADD_ASSET](
        { commit }: AugmentedActionContext,
        asset: Asset
    ): Promise<boolean>;
    [AssetsActionTypes.REMOVE_ASSET](
        { commit }: AugmentedActionContext,
        id: number
    ): Promise<boolean>
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [AssetsActionTypes.ADD_ASSET]({ commit }, asset: Asset) {
        asset.id = state.id
        if (asset.id >= 0 && !!asset.type && !!asset.name && asset.url) {
            commit(AssetsMutationTypes.ADD_ASSET, asset);
            return true;
        }
        return false
    },
    async [AssetsActionTypes.REMOVE_ASSET]({ commit }, id: number) {
        const assetIndex = state.assets.findIndex((x) => x.id === id)
        if (assetIndex >= 0) {
            commit(AssetsMutationTypes.REMOVE_ASSET_ID, assetIndex);
            return true;
        }
        return false;
    }

}