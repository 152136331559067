import {
    Store as VuexStore,
    DispatchOptions,
    CommitOptions,
    Module,
} from 'vuex'

import { RootState } from '@/store'

import { state } from './state'

import { getters, Getters } from './getters'

import type { State } from './state'

export { State }

export type CampaignsStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
    }
}

export const store: Module<State, RootState> = {
    state,
    getters,
    // namespaced: true,
}