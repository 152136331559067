import { GetterTree } from "vuex";

import type { Asset } from "@/@types";

import { RootState } from "@/store";

import { State } from "./state";

export type Getters = {
    getAssets(state: State): Asset[]
    getAssetById(state: State): (id: number) => Asset | undefined
}

export const getters: GetterTree<State, RootState> & Getters = {
    getAssets: (state) => state.assets,
    getAssetById: (state) => (id) => { return state.assets.find(x => x.id === id) },
}