
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { mapGetters } from 'vuex';

export default defineComponent({
    methods: {},
    data() {
        return {
            store: useStore()
        }
    },
    computed: {
        ...mapGetters(['getAssets', 'getAssetById']),
        ...mapGetters(['getUsers', 'getUserById']),
        ...mapGetters(['getCharacters', 'getCharacterById', 'getCharactersByUserId'])
    },
    mounted() {
        console.log(this.getUsers)
    },
})
