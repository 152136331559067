import { MutationTree } from "vuex";

import { Character } from "@/@types";

import { State } from "./state";
import { CharactersMutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
    [CharactersMutationTypes.ADD_CHARACTER](state: S, payload: Character): void;
    [CharactersMutationTypes.REMOVE_CHARACTER_ID](state: S, payload: number): void;
    [CharactersMutationTypes.EDIT_CHARACTER](state: S, payload: { index: number, character: Character }): void;

}

export const mutations: MutationTree<State> & Mutations = {
    [CharactersMutationTypes.ADD_CHARACTER](state: State, character: Character) {
        state.characters.push(character)
        state.id++
    },
    [CharactersMutationTypes.REMOVE_CHARACTER_ID](state: State, index: number) {
        state.characters.splice(index, 1)
    },
    [CharactersMutationTypes.EDIT_CHARACTER](state: State, payload: { index: number, character: Character }) {
        state.characters.splice(payload.index, 1, payload.character)
    }
}