
import VariableTesting from '@/components/VariableTesting.vue'; // @ is an alias to /src
import { defineComponent } from 'vue';
export default defineComponent({
  components: { VariableTesting },
  props: {
    msg: String
  },
  methods: {

  }
})

