import { MutationTree } from "vuex";

import { Asset } from "@/@types";

import { State } from "./state";
import { AssetsMutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
    [AssetsMutationTypes.ADD_ASSET](state: S, payload: Asset): void;
    [AssetsMutationTypes.REMOVE_ASSET_ID](state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [AssetsMutationTypes.ADD_ASSET](state: State, asset: Asset) {
        state.assets.push(asset);
        state.id++
    },
    [AssetsMutationTypes.REMOVE_ASSET_ID](state: State, index: number) {
        state.assets.splice(index, 1)
    }
}